<template>
  <div
    v-if="isVisible"
    class="breadcrumbs"
  >
    <template v-if="breadcrumbs.length">
      <router-link
        class="breadcrumbs__link"
        :to="{ path: routeMixinHomePageRoutePath }"
        :title="translate('seo.title.home')"
      >
        {{ translate('breadcrumbs.homepage') }}
      </router-link>
      <span class="breadcrumbs__divider">/</span>

      <span
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb.title"
      >
        <template v-if="breadcrumb.to">
          <router-link
            :to="breadcrumb.to"
            :title="breadcrumb.titleAttribute || breadcrumb.title"
            class="breadcrumbs__link"
          >
            {{ breadcrumb.title }}
          </router-link>
          <span class="breadcrumbs__divider">/</span>
        </template>

        <span
          v-else
          :key="breadcrumb.title"
          class="breadcrumbs__link -current"
          :title="breadcrumb.titleAttribute"
        >
          {{ breadcrumb.title }}
        </span>
      </span>
    </template>
    <div
      v-else
      class="breadcrumbs__link"
    >
      &nbsp;
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .breadcrumbs {
    &__link,
    &__divider {
      display: inline-block;
      color: $cl-black;
    }

    &__link {
      font-size: 12px;
      &:hover {
        color: $cl-tertiary;
      }

      &.-current {
        &:hover {
          color: $cl-black;
        }
      }
    }

    &__divider {
      text-align: center;
      width: 15px;
      margin: 0;
      opacity: .7;
      vertical-align: baseline;
    }
  }

  .moreJobsView,
  .moreJobsOfferView {
    .breadcrumbs__link {
      &:hover {
        color: $cl-primary-lighten-8;
      }
      &.-current {
        &:hover {
          color: $cl-black;
        }
      }
    }
  }
</style>

<script>
import { mapState } from 'vuex';
import { isMobile } from '@/js/helpers/breakpoints';
import routesMixin from '@/js/mixins/routes';

export default {
  name: 'BreadcrumbTrail',

  mixins: [routesMixin],

  data () {
    return {
      isVisible: true,
    };
  },

  props: {
    showOnMobile: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('breadcrumbs', ['breadcrumbs']),

    homePageRoute () {
      const { href } = this.$router.resolve({ name: 'home' });

      return href.length > 1 ? href.slice(0, -1) : href; // remove trailing slash if href is not '/'
    },
  },

  mounted () {
    this.$nextTick(() => {
      this.shouldNotBeVisible();
    });
    window.addEventListener('resize', this.shouldNotBeVisible);
  },

  destroyed () {
    window.removeEventListener('resize', this.shouldNotBeVisible);
  },

  methods: {
    shouldNotBeVisible () {
      setTimeout(() => {
        this.isVisible = this.showOnMobile || !isMobile();
      }, 0);
    },
  },
};
</script>
