import store from '../store';

export function translate (key, params = {}) {
  const { phrases } = store.state.i18n;

  if (!phrases || !phrases[key]) {
    return key;
  }

  const phrase = phrases[key]
    .replace(/&#39;/g, '\'') // '
    .replace(/&gt;/g, '>'); // >

  return interpolate(phrase, params);
}

export function interpolate (text, params = {}) {
  Object.keys(params).forEach(key => {
    const pattern = new RegExp(`%{${key}}`, 'g');
    text = text.replace(pattern, params[key]);
  });

  return text;
}

export function currentLanguagePrefix () {
  const pathPrefixMatch = window.location.pathname.match(/^(\/[a-z]{2})([\/#].*)?$/);

  return pathPrefixMatch && pathPrefixMatch[1] ? pathPrefixMatch[1].substr(1) : 'en';
}

export function getUrlLanguagePrefix () {
  if (currentLanguagePrefix() === 'en') {
    return '';
  }
  return `/${currentLanguagePrefix()}`;
}
