import breadcrumbsHelper from '@/js/helpers/breadcrumbs';

const mutations = {
  setBreadcrumbs (stateObj, breadcrumbs) {
    stateObj.breadcrumbs = breadcrumbs;
  },
};

const actions = {
  setBreadcrumbs ({ commit }, data) {
    commit('setBreadcrumbs', breadcrumbsHelper.build(data));
  },
};

const state = {
  breadcrumbs: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
