<template>
  <div class="ui error message">
    <div class="header">
      <aj-icon
        shape="bug"
        class="ui bug icon"
      />
      Something went wrong
    </div>
    <p>
      We are really sorry. There was some error while loading data.
      Please try again later or go back to the homepage.
    </p>
    <aj-button
      primary
      @click="goToHomepage"
    >
      Go to homepage
    </aj-button>
  </div>
</template>

<script>
import AjButton from '@appjobs/ui/src/components/AjButton/AjButton';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import { sendGAEvent } from '@/js/helpers/analytics';

export default {
  name: 'BufferError',

  components: {
    AjButton,
    AjIcon,
  },

  props: {
    isSuspiciousLead: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  created () {
    sendGAEvent({
      category: 'RedirectPage',
      action: 'error-on-redirect-page',
      label: new Date().toUTCString(),
      value: Number(this.isSuspiciousLead),
    });
  },

  methods: {
    goToHomepage () {
      this.$router.push({ name: 'home' });

      sendGAEvent({
        category: 'RedirectPage',
        action: 'go-to-homepage-click',
        label: new Date().toUTCString(),
        value: Number(this.isSuspiciousLead),
      });
    },
  },
};
</script>
