const dynamicLinksNoopenerVulnerabilityCheck = () => {
  setInterval(() => {
    const links = Array.from(document.querySelectorAll('a[target]'));

    links.forEach(link => {
      const target = link.getAttribute('target');
      const rel = link.getAttribute('rel');

      if (target && (!rel || rel.indexOf('noopener') === -1)) {
        console.groupCollapsed('Vulnerability detected');
        console.log(link);
        console.error(
          `%cHEY DEV! Unsafe link ${link} is vulnerable to reverse tabnabbing. `
          + 'Use rel="noopener" attribute to prevent it. Read more: '
          + 'https://medium.com/sedeo/how-to-fix-target-blank-a-security-and-performance-issue-in-web-pages-2118eba1ce2f',
          'font-weight: bold; font-size: 1.2em',
        );
        console.groupEnd();
      }
    });
  }, 5000);
};

export default {
  dynamicLinksNoopenerVulnerabilityCheck,
};
