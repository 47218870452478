import { getUtmSource } from '@/js/helpers/gap';
import { get } from './request';

export default {
  getMoreJobsOffers (params = {}) {
    params.utm_source = getUtmSource();

    return get(
      '/job-offers',
      {
        params,
        target: 'ofp-api',
        headers: {
          'X-Referer': 'https://medjobfinder.com',
        },
      },
      { errorMessage: 'Cannot load job offers.' },
    );
  },

  getMoreJobsOffer (params = {}) {
    params.utm_source = getUtmSource();

    return get(
      '/job-offer',
      {
        params,
        target: 'ofp-api',
      },
      { errorMessage: 'Cannot load job offer.' },
    );
  },
};
