<template>
  <div>
    <aj-loader
      :show="!phrases && !messages.length"
      full-size
    />
    <template v-if="phrases">
      <div
        class="dimmable root"
        :class="{ 'dimmed': isModalActive }"
        :style="`margin-top: ${pageOffset}px`"
      >
        <template v-if="messages">
          <div
            ref="flashMessage"
            class="flashMessage"
          >
            <aj-message
              v-for="message in messages"
              :key="message.id"
              :visible="visible"
              :type="message.variation"
              :title="message.title"
              :message="message.message"
              :action-label="message.action"
              @click:close="closeFlashMessage(message.id)"
            />
          </div>
        </template>
        <menu-header
          ref="menuHeader"
          :offset-top="pageOffset"
        />
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <router-view />
        </transition>
        <app-footer />
      </div>
      <modal-placeholder />
      <cookie-nag />
    </template>
    <template v-else>
      <aj-message
        v-for="message in messages"
        :key="message.id"
        :visible="visible"
        :type="message.variation"
        :title="message.title"
        :message="message.message"
        :action-label="message.action"
        @click:close="closeFlashMessage(message.id)"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .root > .loader {
    min-height: 100vh;
  }
</style>

<style lang="scss">
  .flashMessage {
    position: fixed;
    z-index: 10000;
    width: 100%;
    bottom: 0;
    @media screen and (min-width: 769px) {
      top: 0;
      bottom: auto;
    }
  }
</style>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import Cookies from 'js-cookie';
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import AjMessage from '@appjobs/ui/src/components/AjMessage/AjMessage';
import { isMobile } from '@/js/helpers/breakpoints';
import { seoTitle } from '../helpers/seo';
import appFooter from '../components/app-footer';
import menuHeader from '../components/menu-header';
import ModalPlaceholder from '../components/modal-placeholder';
import CookieNag from '../components/cookie-nag';
import { setSourceData } from '../helpers/gap';

export default {
  name: 'RootView',

  components: {
    appFooter,
    menuHeader,
    ModalPlaceholder,
    CookieNag,
    AjLoader,
    AjMessage,
  },

  async beforeRouteUpdate (to, from, next) {
    seoTitle();
    next();
  },

  data () {
    return {
      scrolling: 0,
      vector: 1,
      rocketFuelUrl: null,
      visible: true,
      flashMessageHeight: 0,
      pageOffset: 0,
    };
  },

  computed: {
    ...mapState({
      messages: state => state.messages,
      phrases: state => state.i18n.phrases,
      isModalActive: state => state.modal.show,
      modalData: state => state.modal.data,
      modalName: state => state.modal.name,
    }),
    ...mapState(['city']),
    ...mapState('i18n', ['language']),
    ...mapGetters(['isPushableMessage']),

    isMessage () {
      return this.messages;
    },

    isMobile () {
      return isMobile();
    },
  },

  watch: {
    isMessage (messages) {
      if (messages) {
        this.visible = true;
        this.$nextTick(() => {
          this.updateFlashMessageHeight();
          this.updatePageOffest();
        });
      }
    },

    phrases (data) {
      if (Object.keys(data).length !== 0) {
        this.$nextTick(() => {
          this.updateFlashMessageHeight();
          this.updatePageOffest();
        });
      }
    },
    $route () {
      this.updatePageOffest();
    },
  },

  created () {
    setSourceData(this.$route.query);
    const expirationDate = Date.now() + 60 * 24 * 3600000;

    if (
      this.$route.query.referral_id
      && !Cookies.get('referralData')
    ) {
      Cookies.set(
        'referralData',
        JSON.stringify({
          url: location.href,
          expires_at: expirationDate,
        }),
        { expires: 60 },
      );
    }

    document.documentElement.lang = this.language;
  },

  mounted () {
    this.hideBlindStopper();
  },

  methods: {
    ...mapActions(['setMessage']),
    ...mapActions('modal', ['showModal', 'hideModal']),
    ...mapActions('city', ['setCity']),
    ...mapActions(['deleteMessage']),

    setInitialScreen () {
      return this.$route.query.vt && this.$route.query.vt === '5' ? 'login' : 'signup';
    },

    updateFlashMessageHeight () {
      if (this.$refs.flashMessage) {
        this.flashMessageHeight = (this.isPushableMessage && !this.isMobile)
          ? this.$refs.flashMessage.clientHeight
          : 0;
      }
    },

    updatePageOffest () {
      if (this.$refs.menuHeader) {
        this.pageOffset = this.flashMessageHeight;
      }
    },

    closeFlashMessage (id) {
      this.deleteMessage(id);
      this.visible = false;
    },

    hideBlindStopper () {
      const blindStopper = document.getElementById('vue-blind-stopper');
      blindStopper.classList.add('-hidden');
    },
  },
};
</script>
