import { getSourceData, getUtmSource } from '@/js/helpers/gap';
import userJobOffersService from '@/js/services/user-job-offers';
import store from '../store';

export async function postLeadId (data) {
  return userJobOffersService.postUserJobOffer(data);
}

export function postJobOfferLead ({
  offer,
}) {
  const clickSource = 'directBufferPage';

  const data = {
    id: offer.id,
    source: getSourceData(),
    referral_url: store.getters.referralUrl,
    locale: store.state.i18n.language,
    click_source: clickSource,
    utm_source: getUtmSource(),
    offer,
    isFullTimeJob: true,
  };

  return postLeadId(data);
}
