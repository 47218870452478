import router from 'router';
import { removeQueryParamsFromUrl, replaceLastSegmentInPathname } from '@/js/helpers/url';

/**
 * Handles the application process for more job offers.
 * It sends a Google Analytics event and then redirects the user to the job offer URL.
 *
 * @param {Object} offer - The job offer details.
 * @param {Object} offer.partner - The partner details of the offer.
 * @param {string} offer.partner.name - The name of the partner.
 * @param {string} offer.feedId - The feed ID of the offer.
 * @param {Object} [offer.city] - The city details of the offer (optional).
 * @param {string} [offer.city.name] - The name of the city (optional).
 * @param {string} [offer.city.state] - The state of the city (optional).
 * @param {number} [offer.weight] - The weight of the offer (optional).
 * @param {string} offer.url - The URL to redirect to for the job offer.
 * @param {string} clickContext - Place in the app layout
 *                                where user clicked and application was executed
 */

import { sendGAEvent } from './analytics';
import { constructFullCityName } from './str';

export function onMoreJobsOfferApply (offer, clickContext) {
  const GAEventObject = {
    category: 'MoreJobsPage-jobApply',
    action: offer.partner.name,
    label: constructFullCityName(offer.city),
    value: offer.weight || null,
    customParams: {},
  };

  const moreJobsUrlParams = new URL(offer.url).searchParams;
  const subId = Number(moreJobsUrlParams.get('subid'));
  const clickId = moreJobsUrlParams.get('clickid');
  if (subId) {
    GAEventObject.customParams.eventCustom_subId = subId;
  }
  if (clickId) {
    GAEventObject.customParams.eventCustom_clickId = clickId;
  }
  if (offer.feedId) {
    GAEventObject.customParams.eventCustom_feedId = Number(offer.feedId);
  }
  if (clickContext) {
    GAEventObject.customParams.eventCustom_clickContext = clickContext;
  }

  sendGAEvent(GAEventObject);
  const redirectToPartnerUrl = router.resolve({
    name: 'redirect_to_partner',
    params: {
      citySlug: offer.city.slug,
      partnerSlug: offer.partner.slug,
      feed: offer.feedId,
      id: offer.hashId || offer.id,
    },
  }).href;

  window.location.href = redirectToPartnerUrl;
}

/**
 * Modifies the subid parameter in the given URL if it exists.
 *
 * @param {string} url - The original URL.
 * @param {string} newSubId - The new value for the subid parameter.
 * @returns {string} The URL with the modified subid parameter, if subid exists;
 *                   otherwise, the original URL.
 */
export function modifySubIdInUrl (url, newSubId) {
  const urlObj = new URL(url);
  if (urlObj.searchParams.has('subid')) {
    urlObj.searchParams.set('subid', newSubId);
    return urlObj.href;
  }

  return url;
}

export function prepareFullTimeJobsCanonicalUrl (jobOffer, requestPath) {
  const baseUrl = `https://www.medjobfinder.com${requestPath}`;
  const cleanedUrl = removeQueryParamsFromUrl(baseUrl);

  return replaceLastSegmentInPathname(cleanedUrl, jobOffer.slugTitle);
}
