<template>
  <div>
    <aj-loader
      :show="isPageLoading"
      class="componentLoader"
    />
    <more-jobs-offer-expired v-if="isJobOfferExpired()" />
    <more-jobs-offer-view v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import { translate } from '@/js/helpers/i18n';
import { prepareFullTimeJobsCanonicalUrl } from '@/js/helpers/job-offers';
import { seoTitle, setCanonicalUrl } from '@/js/helpers/seo';
import { constructFullCityName } from '@/js/helpers/str';
import { EventBus } from '@/js/helpers/utils';
import store from '@/js/store';
import { OFFER_STATUS_EXPIRED } from '@/js/enums/jobOffersStatuses';

function isJobOfferExpired () {
  const jobOffer = store.state.moreJobs.moreJobsOffer;

  return jobOffer.status === OFFER_STATUS_EXPIRED;
}

async function handleRoute (to) {
  await store.dispatch('moreJobs/getMoreJobsOffer', to.params);
  const jobOffer = store.state.moreJobs.moreJobsOffer;

  if (jobOffer?.status) {
    if (isJobOfferExpired()) {
      seoTitle(translate('jobOffer.expired.title'));
    } else {
      seoTitle(translate('seo.title.jobOffer', {
        cityName: constructFullCityName(jobOffer.city),
        partnerName: jobOffer.partner.name,
      }));
      setCanonicalUrl(prepareFullTimeJobsCanonicalUrl(jobOffer, to.path));
    }
  }
}

export default {
  name: 'MoreJobsOfferRoute',

  components: {
    AjLoader,
    MoreJobsOfferView: () => import('@/js/views/MoreJobsOffer/more-jobs-offer-view'),
    MoreJobsOfferExpired: () => import('@/js/views/MoreJobsOffer/more-jobs-offer-expired'),
  },

  computed: {
    ...mapState(['moreJobs']),
  },

  data () {
    return {
      isJobOfferExpired,
      isPageLoading: true,
    };
  },

  created () {
    EventBus.$on('more-jobs-offer-view-loaded', () => {
      this.isPageLoading = false;
    });
  },

  async beforeRouteEnter (to, from, next) {
    await handleRoute(to);
    next();
  },

  async beforeRouteUpdate (to, from, next) {
    if (to.path === from.path) {
      return;
    }

    await handleRoute(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
  .componentLoader {
    min-height: 100vh;
    position: relative;
  }
</style>
