<template>
  <!-- eslint-disable max-len vue/max-len -->
  <div
    class="ui dropdown inline user-menu"
    :class="{
      'active visible': opened,
    }"
    :style="[menuWidth]"
  >
    <div
      class="mobile-inital"
      @click="handleShow"
    >
      <aj-icon
        class="navigation-menu"
        shape="navigation-menu"
      />
    </div>
    <div
      class="new menu transition"
      :class="{ 'visible': opened }"
      @click.self="close"
    >
      <div class="mobileMenu__close">
        <aj-icon
          class="mobileMenu__closeIcon"
          shape="close-bold"
          @click="close"
        />
      </div>

      <div class="mobile-menu row">
        <div class="mobileMenu__content">
          <div class="mobileMenu__item">
            <router-link
              :to="{ name: 'jobs' }"
              :title="translate('footer.moreJobs')"
              class="mobileMenu__link"
              @click.native="linkClick"
            >
              Explore jobs
            </router-link>
          </div>
          <div class="mobileMenu__item">
            <router-link
              :to="{ name: 'about' }"
              :title="translate('seo.title.about')"
              class="mobileMenu__link"
              @click.native="linkClick"
            >
              {{ translate('footer.about') }}
            </router-link>
          </div>
          <div class="mobileMenu__item">
            <router-link
              :to="{ name: 'contact' }"
              :title="translate('footer.contact')"
              class="mobileMenu__link"
              @click.native="linkClick"
            >
              {{ translate('footer.contact') }}
            </router-link>
          </div>
          <div class="mobileMenu__item">
            <router-link
              :to="{ name: 'terms' }"
              :title="translate('footer.terms')"
              class="mobileMenu__link"
              @click.native="linkClick"
            >
              {{ translate('footer.terms') }}
            </router-link>
          </div>
          <div class="mobileMenu__item">
            <router-link
              :to="{ name: 'policy' }"
              :title="translate('footer.policy')"
              class="mobileMenu__link"
              @click.native="linkClick"
            >
              {{ translate('footer.policy') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .menu {
    .navigation-menu {
      fill: $cl-secondary;
    }
  }

  .mobile-menu {
    color: $cl-secondary;
  }

  .mobileMenu {
    &__close {
      height: 50px;
    }
    &__closeIcon {
      position: absolute;
      top: 15px;
      left: 15px;
      width: 16px;
      height: 16px;
      color: $cl-secondary;
      cursor: pointer;
    }
    &__link {
      display: inline-block;
      width: 100%;
      padding: 15px;
      font: 16px $font-primary;
      color: $cl-secondary;
      transition: .1s ease;

      &:hover {
        background-color: $cl-primary;
        color: $cl-white;
      }
    }
  }
</style>

<style lang="less" scoped>
  @fontColor: @lightBlack;

  .user-menu {
    .mobile-inital {
      display: flex;
      align-items: center;
    }

    &.ui.ui.ui.dropdown > .new.menu {
      border: 0;
      z-index: 100;
      max-width: 100%;
      right: -10px;
      top: 40px;
      width: 300px;
      @media screen and (max-width: @largestMobileScreen) {
        height: 100vh;

        &.visible {
          position: absolute;
          top: -6px;
          right: -15px;
        }

        .mobile-menu {
          width: 300px;
        }
      }
    }
    .navigation-menu {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }
</style>

<script>
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import { isMobile } from '@/js/helpers/breakpoints';
import onOutsideClickMixin from '@/js/mixins/on-outside-click';
import { EventBus } from '@/js/helpers/utils';
import routesMixin from '@/js/mixins/routes';

export default {
  name: 'UserMenu',

  components: {
    AjIcon,
  },

  mixins: [
    onOutsideClickMixin,
    routesMixin,
  ],

  data () {
    return {
      menuWidth: {
        right: '0%',
      },
      opened: false,
      active: '',
    };
  },

  computed: {
    isMobile () {
      return isMobile();
    },
  },

  watch: {
    $route (to) {
      this.active = to.name;
    },
  },

  mounted () {
    this.active = this.$route.name;
  },

  methods: {
    // From mixin, fired when user clicks outside of this.$el
    onOutsideElementClick () {
      if (this.opened) {
        this.close();
      }
    },

    close () {
      EventBus.$emit('close-new-menu');
      setTimeout(() => {
        this.opened = false;
        this.menuWidth = { right: '0%', transition: '0s' };
      }, 0);
    },

    linkClick () {
      this.close();
    },

    isActive (routeName) {
      return this.active.indexOf(routeName) === 0;
    },

    handleShow () {
      if (this.opened) {
        this.opened = false;
      } else {
        this.opened = true;
        EventBus.$emit('opened-new-menu');
        if (isMobile()) {
          this.menuWidth = { right: '-2000%', transition: '0s' };
          setTimeout(() => {
            this.menuWidth = { right: '0', top: '-8px' };
          }, 0);
        }
      }
    },
  },
};
</script>
