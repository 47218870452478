const mutations = {
  showModal (stateObj, settings) {
    // Do not change name if not provided (keeps modal loaded but not shown)
    if (settings.name) {
      stateObj.name = settings.name;
    }
    stateObj.show = settings.show;
    stateObj.data = { ...(settings.data || {}) };
    stateObj.confirmed = settings.confirmed;
  },
};

const actions = {
  showModal ({ commit }, data) {
    commit('showModal', {
      name: data.name,
      show: true,
      data: data.data,
      confirmed: data.confirmed,
    });
  },

  hideModal ({ commit }) {
    commit('showModal', {
      show: false,
      data: {},
      confirmed: false,
    });
  },

  confirmModal ({ commit }) {
    commit('showModal', {
      show: true,
      data: {},
      confirmed: true,
    });
  },
};

const getters = {
  hideElements (stateObj) {
    return stateObj.show && stateObj.name === 'Auth';
  },
};

const state = {
  name: '',
  show: false,
  data: {},
  confirmed: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
