const phrases = {
  "CityCard.viewOffers": "View Offers",
  "about.header1": "About MedJobFinder",
  "about.header2": "How it works",
  "about.punchline1": "To give you all opportunities",
  "about.punchline2": "to work when and where you want!",
  "addReview.leaveAsAnonymous": "Leave this review as <strong>Anonymous<\/strong>.",
  "addReview.saveAndNext": "Save and next",
  "addReview.skip": "Skip this part",
  "addedCity.header": "Thank you for adding a request!",
  "addedCity.text": "We will try to add your city as soon as possible, when it happens we will let you know by email.",
  "addedCity.text.guest": "We will try to add your city as soon as possible.",
  "attention.addReview": "Add review",
  "attention.iDidNotWorkForThisPartner": "I did not work with this company",
  "attention.review": "Hi there! Have you worked with <b>%{partnerName}<\/b>?",
  "attention.worktown.afterChange": "Your worktown has been changed. You can also change it in your profile.",
  "attention.worktown.prompt": "Do you want to set %{cityName} as your worktown?",
  "auth.errors.sign_in": "Please sign in",
  "bicycleSection.cities": "cities",
  "bicycleSection.jobOffers": "job offers",
  "bicycleSection.thousands": "K",
  "bicycleSection.workers": "workers found their gigs",
  "breadcrumb.noWorktown": "Worktown not set",
  "breadcrumbs.city": "Jobs in %{cityName}",
  "breadcrumbs.cityCategory": "%{categoryName} jobs in %{cityName}",
  "breadcrumbs.homepage": "Homepage",
  "breadcrumbs.jobOffer": "%{partnerName} jobs in %{cityName}",
  "breadcrumbs.jobOffer.expired": "Expired offer",
  "breadcrumbs.partnerView": "%{partnerName} gigs",
  "button.close.label": "Close",
  "button.label.save": "Save",
  "buttonText.companySite": "Apply on Company Site",
  "carouselGigs.checkAllButton": "Check all gigs",
  "carouselGigs.checkAllLink": "Check all gigs for %{partnerName}",
  "carouselGigs.header": "Real-time gig postings for %{partnerName}",
  "carouselGigs.headerCategory": "Real-time gig postings for %{categorySlug}",
  "categories.otherForCity.label": "Other categories for %{city}",
  "categories.selectedForCity.label": "Selected job categories for %{city}",
  "category.checkOffers": "Check all jobs",
  "category.jobOffersCount": "Jobs in %{cityName}",
  "categoryCard.viewOffers": "View offers",
  "categoryJobs.all": "All jobs",
  "categoryJobs.moreAbout": "More about",
  "categoryJobs.single": "jobs",
  "change_password.current_password": "Current password",
  "change_password.not_right_password": "Seems it\u2019s not the right password. Please try again or",
  "change_password.not_right_password_suffix": "reset your password",
  "change_password.password_changed": "Your password has been successfully changed",
  "city-card.city-nearest-to-you": "City nearest you",
  "city-card.city-nearest-worktown": "City nearest your work town",
  "city.askQuestions": "Ask questions about jobs in %{cityName}",
  "city.reviews.header": "Rating and reviews for %{cityName}",
  "cityCategory.askQuestions": "Ask questions about %{categoryName} in %{cityName}",
  "cityCategory.button.allOffers": "Browse all %{categoryName} jobs",
  "cityCategory.button.learnMore": "Learn More",
  "cityCategory.button.moreCategories": "More categories",
  "cityCategory.button.showOffers": "Show offers",
  "cityCategory.checkOtherCategories": "Check other categories for %{cityName}",
  "cityCategory.reviews.header": "Overall rating and reviews for %{categoryName} - %{cityName}",
  "cityCategory.title": "%{categoryName} in %{cityName}",
  "common.add": "Add",
  "common.approve": "Approve",
  "common.back": "Back",
  "common.edit": "Edit",
  "common.no": "no",
  "common.reload": "Reload",
  "common.save": "Save",
  "common.saving": "Saving\u2026",
  "common.yes": "yes",
  "contact.header.agency": "Interested in content collaboration? Contact our agency!",
  "contact.header.content": "Content issues",
  "contact.header.faq": "You need to start somewhere, but you don\u2019t need to start alone.",
  "contact.header.faq ": "",
  "contact.header.general": "Haven\u2019t found answers in our FAQ? Talk to our team!",
  "contact.header.sales": "Partner with us",
  "contact.sentence.faq": "Check out the <faq-link>FAQ<\/faq-link> to find step-by-step advice on what to do next from other gig workers.",
  "editProfile.success.message": "Changes saved.",
  "editProfile.success.title": "Great!",
  "employmentType": "part-time \/ weekend \/ flexible",
  "employmentTypeLabel": "Employment type",
  "error.info": "Oops, something went wrong",
  "error.request.limit": "You have reached the limit of times you can change your password. Try again later.",
  "error.tryAgain": "Try again",
  "extendedReview.label.latest": "Latest review",
  "extendedReview.label.recommended": "Recommended for you",
  "faq.cityCategories.answer": "Other available type of jobs in %{cityName} are %{cityCategories}",
  "faq.cityCategories.question": "What other types of jobs can I find in %{cityName}?",
  "faq.earnings.answer": "By working with %{partnerName} in %{cityName} you can earn %{salaryString}",
  "faq.earnings.question": "How much can I earn with %{partnerName} in %{cityName}?",
  "faq.heading": "Frequently Asked Questions",
  "faq.jobRequirements.question": "What are requirements to start working with %{partnerName} in %{cityName}?",
  "faq.moreJobsInCityQuestion.answer": "Check our new %{moreJobsInCityLink} section to explore more job opportunities in %{cityName}!",
  "faq.moreJobsInCityQuestion.question": "What other job opportunities are there in %{cityName}?",
  "faq.nearbyCities.answer": "Nearby cities of %{cityName} where you can find a job are %{nearbyCities}",
  "faq.nearbyCities.question": "What cities are nearby %{cityName} where I can find a job?",
  "faq.partnerOtherCities.answer": "Closest cities to %{cityName} where %{partnerName} is hiring are: %{partnerOtherCities}",
  "faq.partnerOtherCities.question": "Are there other cities where %{partnerName} is hiring?",
  "faq.popularOffersInCity.answer": "The top companies offering opportunities now in %{cityName} are %{popularOffers}",
  "faq.popularOffersInCity.question": "What companies are offering opportunities now in %{cityName}?",
  "faqWorkshopPopup.heading": "Hey! Want to take part in a short study for the chance to win a $50 gift card?",
  "faqWorkshopPopup.reject": "No thanks",
  "faqWorkshopPopup.submit": "Sure, let\u2019s do it",
  "favCategories.categoryAddedToFavourite": "We added %{category} to your favourite categories.",
  "favCategories.goToProfile": "Go to profile",
  "favCategories.isCategoryFavourite": "Are you interested in %{category} jobs?",
  "favCategories.no": "NO",
  "favCategories.widget.label": "Help us show you better results",
  "favCategories.yes": "YES",
  "filters.apply.button": "Apply filters",
  "filters.category.label": "Job categories",
  "filters.jobType.label": "Job type",
  "filters.modal.title.desktop": "Filters",
  "filters.modal.title.mobile": "Filters and sorting",
  "filters.reset.button": "Clear filters",
  "filters.sorting.label": "Sort by",
  "filters.sorting.placeholder": "Sorting options",
  "footer.about": "About",
  "footer.blog": "Blog",
  "footer.careers": "Careers",
  "footer.contact": "Contact",
  "footer.contactSales": "Partner with us",
  "footer.copyrights.text": "Copyright %{currentYear} MedJobFinder. All Rights Reserved",
  "footer.gap.clients": "Our partners",
  "footer.gap.header": "For companies",
  "footer.gap.homepage": "Hire on demand workers with Sourcie",
  "footer.gap.institute": "The Future of Work Institute",
  "footer.gap.pricing": "Post your app job",
  "footer.impressum": "Impressum",
  "footer.moreJobs": "Explore Healthcare and Medical jobs",
  "footer.nearbyCities": "Nearest Cities",
  "footer.newestCities": "Newest Cities",
  "footer.policy": "Privacy Policy",
  "footer.services": "Services",
  "footer.sitemap": "Sitemap",
  "footer.stayConnected": "Stay Connected",
  "footer.terms": "Terms",
  "footer.topCities": "Top Cities",
  "footer.title.exploreJobs": "Explore Healthcare and Medical jobs",
  "form.field.mandatory": "This field is mandatory",
  "form.placeholder.enterValue": "Enter value",
  "form.send": "Send",
  "form_validation.type_firstName_first": "Please type your First name",
  "form_validation.type_lastName_first": "Please type your Last Name",
  "general.loading": "Loading",
  "gigJobs.allGigs": "All Gigs",
  "gigJobs.login": "Log in",
  "gigJobs.moreAbout": "More about",
  "gigJobs.noGigs": "Sorry, there are no gigs available right now",
  "gigJobs.postedOn": "Posted on",
  "gigJobs.show": "Show",
  "gigsListing.newGigsAll": "new gigs - added today",
  "gigsListing.newGigsPerCategory ": "New gigs for %{categorySlug} added today",
  "gigsListing.newGigsPerPartner": "new gigs for %{partnerName} added today",
  "helpfulness.headline.finish": "Thank you for your feedback!",
  "helpfulness.headline.provide_feedback": "We\u2019re sorry to hear that. If there is a specific reason why the information was not helpful for you, please share it with us:",
  "helpfulness.headline.start": "Was this information helpful?",
  "homepage.categories.button_check_all": "Check all categories",
  "homepage.categories.card.button_label": "View jobs",
  "homepage.categories.header": "Browse gigs in %{cityName}",
  "homepage.hero.partners.headline": "Over 100k workers have already started working and found gigs with:",
  "homepage.hero.partners.other": "and 1300+ other companies",
  "homepage.hero.subtitle": "Find the right ones for you.",
  "homepage.hero.title": "Flexible job offers tailored to you",
  "info.cookieNotice": "This website uses cookies to improve user experience. <a href=\"%{termsLink}\">Find out more<\/a>",
  "interlinking.find_your_next": "Find your next gig in %{cityName}",
  "interlinking.locations_nearby": "Locations nearby",
  "interlinking.popular_job_categories": "Popular job categories",
  "interlinking.popular_jobs": "Popular jobs",
  "jobOffer.addAssetsToList": "Add resources to list",
  "jobOffer.addReview": "Add Review",
  "jobOffer.assetsRequiredByPartner": "%{partner} asks you to prove you have the following",
  "jobOffer.category": "Category",
  "jobOffer.cityCategoryLink.more": "More",
  "jobOffer.cityLink.offers": "Offers",
  "jobOffer.compare": "Add to compare",
  "jobOffer.customerAvailability.dict.average": "Ok amount of tasks",
  "jobOffer.customerAvailability.dict.high": "Lots of tasks",
  "jobOffer.customerAvailability.dict.low": "A few tasks",
  "jobOffer.customerAvailability.dict.weekend": "Only weekend tasks",
  "jobOffer.description.postedDate": "Posted date",
  "jobOffer.description.validThrough": "Valid through",
  "jobOffer.directApply.button.continue_application": "Continue application",
  "jobOffer.directApply.button.continue_guest": "Continue as Guest",
  "jobOffer.directApply.buttonText": "Direct Apply",
  "jobOffer.directApply.description.guest": "Don\u2019t bother creating an account - all we want is the necessary data for the job application",
  "jobOffer.directApply.description.user": "Sign in with your account for a faster application and save the required info for next time",
  "jobOffer.directApply.description.user.need_data": "Some necessary data in your account is missing. Please provide it to start the job application process",
  "jobOffer.directApply.headline.guest": "Apply as Guest",
  "jobOffer.directApply.headline.user": "Apply as User",
  "jobOffer.directApply.thank_you_page.description": "Thank you! Your application has been received.",
  "jobOffer.directApply.thank_you_page.homepage": "Go to homepage",
  "jobOffer.earnings": "Earnings",
  "jobOffer.earnings.dataByPartner": "Data provided by %{partnerName}",
  "jobOffer.earnings.dataByUnveel": "Data provided by Unveel",
  "jobOffer.earnings.unveel.averageEarningsPerGig": "Average earnings per gig:",
  "jobOffer.earnings.unveel.averageNumberOfGigs": "Average number of gigs for worker:",
  "jobOffer.earnings.unveel.basedOnGigs": "(based on %{totalGigs} gigs)",
  "jobOffer.earnings.unveel.totalGigs": "Total gigs:",
  "jobOffer.earnings.unveel.totalIncome": "Total income:",
  "jobOffer.earnings.unveel.totalWorkers": "Total workers:",
  "jobOffer.earnings.unveelModalHeadline": "%{partnerName} data provided by",
  "jobOffer.expired.message": "Unfortunately, this job is no longer available. However, we have plenty of other opportunities available on our website that may interest you!",
  "jobOffer.expired.title": "This job has expired",
  "jobOffer.getJob": "Quick apply",
  "jobOffer.goToPartner": "Go to %{partnerName} page",
  "jobOffer.header.commonExpenses": "Expenses",
  "jobOffer.header.description": "Working with %{partner} in %{city}",
  "jobOffer.header.description2": "Make money with %{partner} in %{city}",
  "jobOffer.header.earnings": "How much %{partner} pays in %{city}",
  "jobOffer.header.fees": "Fees",
  "jobOffer.header.howToStart": "How do I apply for %{partner} in %{city}?",
  "jobOffer.header.howToUber": "How do I sign up to drive with %{partner} in %{city}?",
  "jobOffer.header.howToUberEats": "How do I sign up to deliver with Uber Eats?",
  "jobOffer.header.jobRequirements": "Skills\/experience\/other information",
  "jobOffer.header.joiningBonus": "Sign up bonus",
  "jobOffer.header.overview": "What is %{partner}?",
  "jobOffer.header.uberEats": "Uber Eats is a food delivery platform available in %{city}.",
  "jobOffer.header.uberEatsRequirements": "Requirements",
  "jobOffer.header.whatYouNeedToStart": "%{partner} requirements in %{city}",
  "jobOffer.jobRequirements": "What you need",
  "jobOffer.loading": "Loading\u2026",
  "jobOffer.location": "Location: %{cityName}",
  "jobOffer.location.simple": "Location",
  "jobOffer.more": "Read more",
  "jobOffer.more_jobs.city": "More jobs in %{city}",
  "jobOffer.noAssetsAddedByUsers": "There are no resources added by members to this job",
  "jobOffer.noAssetsRequireToStartWorking": "%{partner} hasn\u2019t provided any information",
  "jobOffer.noAssetsToAssign": "No resources to assign",
  "jobOffer.nogigs_available": "No gigs available",
  "jobOffer.notFound": "Sorry. We could not find the offer.",
  "jobOffer.not_available": "This job is not available for application now.",
  "jobOffer.otherOffers.divider": "We cannot guarantee that this company is offering opportunities",
  "jobOffer.otherOffers.header": "Verified jobs that are hiring",
  "jobOffer.overview": "Overview",
  "jobOffer.partnerReviews": "%{partnerName} Reviews",
  "jobOffer.quick_summary": "Quick summary",
  "jobOffer.requirementsMet": "Ready to go!",
  "jobOffer.requirementsNotMet": "Resources required",
  "jobOffer.reviews": "Reviews",
  "jobOffer.salaryUnits.activity": "%{salary} per activity",
  "jobOffer.salaryUnits.booking": "%{salary} per booking",
  "jobOffer.salaryUnits.carWash": "%{salary} per car wash",
  "jobOffer.salaryUnits.cleaning": "%{salary} per cleaning",
  "jobOffer.salaryUnits.day": "%{salary} per day",
  "jobOffer.salaryUnits.delivery": "%{salary} per delivery",
  "jobOffer.salaryUnits.drivingGig": "%{salary} per driving gig",
  "jobOffer.salaryUnits.experience": "%{salary} per experience",
  "jobOffer.salaryUnits.hour": "%{salary} per hour",
  "jobOffer.salaryUnits.item": "%{salary} per item",
  "jobOffer.salaryUnits.job": "%{salary} per gig",
  "jobOffer.salaryUnits.lesson": "%{salary} per lesson",
  "jobOffer.salaryUnits.massage": "%{salary} per massage",
  "jobOffer.salaryUnits.month": "%{salary} per month",
  "jobOffer.salaryUnits.party": "%{salary} per party",
  "jobOffer.salaryUnits.passenger": "%{salary} per passenger",
  "jobOffer.salaryUnits.photo": "%{salary} per photo",
  "jobOffer.salaryUnits.plate": "%{salary} per plate",
  "jobOffer.salaryUnits.post": "%{salary} per post",
  "jobOffer.salaryUnits.rental": "%{salary} per rental",
  "jobOffer.salaryUnits.ride": "%{salary} per ride",
  "jobOffer.salaryUnits.service": "%{salary} per service",
  "jobOffer.salaryUnits.shoppingTrip": "%{salary} per shopping trip",
  "jobOffer.salaryUnits.student": "%{salary} per student",
  "jobOffer.salaryUnits.survey": "%{salary} per survey",
  "jobOffer.salaryUnits.walk": "%{salary} per walk",
  "jobOffer.salaryUnits.week": "%{salary} per week",
  "jobOffer.salaryUnits.work": "%{salary} per work",
  "jobOffer.salaryUnits.year": "%{salary} per year",
  "jobOffer.see_similar": "Click here to see similar offers",
  "jobOffer.selectAssetsForJobOffer": "<b>Select what was needed to start working with %{partner}<\/b> (based on your experience)",
  "jobOffer.showAssets": "Show",
  "jobOffer.tab.description": "Description",
  "jobOffer.tab.gigs": "Gigs",
  "jobOffer.tab.reviews": "Reviews",
  "jobOffer.typeOfAssets": "Requirement type",
  "jobOffer.weAreSavingAssets": "We are saving your information for this app job\u2026",
  "jobOfferTile.addReview": "Add review",
  "jobOffers.emptyCategory": "Sorry, no offers were found with selected filters",
  "jobOffers.loadingMore": "Loading more job suggestions",
  "jobOffers.noRecommended": "Sorry we don\u2019t have anything for you at the moment. Check again later!",
  "jobOffers.pagination.next_page": "Next page",
  "jobOffers.pagination.previous_page": "Previous page",
  "jobOffers.sort.mostPopular": "Most popular",
  "jobOffers.sort.myAssets": "My Resources",
  "jobOffers.sort.newest": "Newest",
  "jobOffers.sort.recommended": "Recommended for you",
  "jobOffersCard.title": "Work with %{partnerName}",
  "jobOffersCard.toReview.addReview": "Add review",
  "jobOffersCard.toReview.header": "Submit reviews",
  "jobOffersCard.toReview.visitor.employersNumber": "%{number} work opportunities waiting for you",
  "jobOffersCard.toReview.visitor.header": "Your job",
  "jobOffersCard.toReview.visitor.signUpButton": "Join Now",
  "jobOffersCard.toReview.visitor.signUpLink": "Sign up and start working",
  "jobType.options.contractor": "Contractor",
  "jobType.options.fullTime": "Full-Time",
  "jobType.options.partTime": "Part-Time \/ Flexible",
  "leaveEmailForAlerts.buttonLabel": "Save Alert",
  "leaveEmailForAlerts.heading": "Save your job alert",
  "leaveEmailForAlerts.subHeading": "Stay ahead of the latest job openings in your city.",
  "mainGigsTab.companies": "Companies",
  "mainGigsTab.gigs": "List of gigs",
  "mainMenu.checkProfile": "Check your profile",
  "mainMenu.header": "Unlock Hundreds of More Jobs in %{cityName}!",
  "mainMenu.logoSlogan": "Make money with apps",
  "mainMenu.logout": "Log out",
  "mainMenu.myAssets": "My Resources",
  "mainMenu.myDesk": "My Desk",
  "mainMenu.myProfile": "My Profile",
  "mainMenu.myReviews": "My Reviews",
  "mainMenu.services": "Services in %{country}",
  "matchedOffers.foundNumberOffers": "We have found %{number} jobs for you.",
  "matchedOffers.goToOffers.label": "My app jobs",
  "menu.community.desc": "Learning \/ connecting \/ sharing",
  "menu.gigs": "Gigs",
  "menu.gigs.desc": "See the jobs offered in Your city",
  "menu.myDesk.desc": "Track earnings, get insurance or manage Your taxes",
  "menu.resources": "Resources",
  "menu.services.desc": "Get the resources needed for your gig",
  "menu.viewProfile": "View profile",
  "modal.favouriteCategories.header": "Pick job categories that you are interested in",
  "modal.favouriteCategories.saveButton": "Save",
  "moreJobs.listing.banner.description": "Check all available job opportunities in %{cityName}",
  "moreJobs.listing.banner.headline": "Didn\u2019t find what you were looking for?",
  "moreJobs.listing.banner.linkText": "Find more jobs",
  "moreJobs.offer.headline": "The most recent job opportunities",
  "moreJobs.offer.headline.filteredCity": "in %{city}",
  "moreJobs.offer.headline.keyword.jobs": "%{keyword} Jobs",
  "moreJobs.offer.headline.mostRecentJobs": "Most Recent Jobs",
  "moreJobs.offer.headline.searchResults": "Search results for",
  "moreJobs.offerView.otherOffers": "More jobs in other cities",
  "moreJobs.offerView.otherOffersInCity": "Other offers in %{cityName}",
  "moreJobs.openInNewTab": "Open in a new tab",
  "moreJobs.search.buttonLabel": "Search",
  "optionalEmailForm.buttonLabel": "Continue as guest",
  "optionalEmailForm.emailLabel": "Enter your email",
  "optionalEmailForm.heading": "Be the first to hear about similar jobs.",
  "optionalEmailForm.privacyPolicy": "I agree to receive offers according to conditions in our <privacy-link>Privacy Policy<\/privacy-link>",
  "ownSchedule": "own schedule",
  "pagination.next": "Next",
  "pagination.page": "Page %{number}",
  "pagination.previous": "Previous",
  "parsonalizeOffersButton.header": "Tell us about yourself. You will get personalized job suggestions.",
  "parsonalizeOffersButton.label": "Get started",
  "partner.see_other_cities": "See other cities where %{partnerName} is offering opportunities",
  "partnerIndex.emptyPartnersList": "Sorry, no companies were found with the selected category filter",
  "partnerIndex.headline": "Start working with these companies now!",
  "partnerIndexItem.jobOffersCount": "Offers in %{jobOffersCount} cities",
  "partnerView.descriptionSection.headline": "Featured %{partnerName} offers",
  "partnerView.expandOffers": "Show all %{offersLength} offers",
  "partnerView.filters.filterOffers": "Find %{partnerName} offer in your city",
  "partnerView.filters.searchByCity": "Search by city",
  "partnerView.filters.searchByCountry": "Search by country",
  "partnerView.filters.subtitle": "Use the country and city filters below",
  "partnerView.jobOffer.details": "More details",
  "promotedBanner": "Promoted app job",
  "promotedBanner.button": "List your car now!",
  "promotedBanner.description": "Rent your car out to Turo\u2019s community of approved drivers and make money.",
  "promotedBanner.header": "Make money from your car!",
  "rating.count": "ratings",
  "rating.showReviews": "Show",
  "recommended.new": "NEW",
  "redirect.closeWindow": "Close window",
  "redirect.info": "You are going to the %{partnerName} page, but we are still by your side. You can come back and let others know how your application and first day went!",
  "redirect.info.headline": "Please wait a moment. You\u2019re being redirected to the %{partnerName} page.",
  "redirect.info.subtitle": "You can come back and let others know how your application and the first day went!",
  "redirect.missingAssets": "Oops! You don\u2019t seem to meet the requirements for %{partnerName}. You are missing the following resources:",
  "review.addMore": "Add more",
  "review.noThanks": "No, thanks",
  "review1.city": "New York City",
  "review1.review": "Good work of trying to connect people who are trying to provide for themselves or their family.",
  "review1.username": "Amy H",
  "review2.city": "New York City",
  "review2.review": "It\u2019s a site built for us, and provides useful resources on getting started quickly.",
  "review2.username": "Jacob C.",
  "review3.city": "Stockholm",
  "review3.review": "It\u2019s easy to get started, you easily choose a city and can choose between different categories or simply the most popular jobs. Couldn\u2019t be easier, great job!",
  "review3.username": "Emilio",
  "search.city": "Type your location",
  "search.city.variant1": "or type location",
  "search.city.variant2": "Type location",
  "search.city.variant3": "Please, type location",
  "search.company": "Find company",
  "search.company.notFound": "Company not found",
  "search.keywords.placeholder": "Job title, keywords",
  "search.noResults": "No results, would you like to add",
  "search.notFound": "City not found",
  "search.requestCity": "Yes I want to",
  "search.searching": "Searching\u2026",
  "search.suggestedCities": "Suggested cities",
  "search.userLanguage": "Choose language\u2026",
  "search.worktownPrompt": "Change my worktown to %{cityName}",
  "search_for_jobs.button_label": "Search for jobs near you",
  "search_for_jobs.or_type_location": "or type location where you want to work",
  "search_for_jobs.permission_deny": "To see the cities we need permission to use your location",
  "search_for_jobs.type_location": "Type location where you want to work",
  "seo.description.about": "Learn about MedJobFinder, a dedicated platform for medical and healthcare job seekers and employers. Discover our mission to bridge talent with opportunity in the healthcare sector, offering streamlined job search and application processes.",
  "seo.description.contact": "Get in touch with MedJobFinder for any inquiries. Our team is ready to assist with your questions and support your healthcare job search journey.",
  "seo.description.home": "Find your next medical job at MedJobFinder. Explore top positions, apply directly, and advance your healthcare career today!",
  "seo.description.jobOffer": "Interested in a %{cardTitle} position at %{partnerName} in %{cityName}? Get all the information about this exciting role, including responsibilities and qualifications, and apply now.",
  "seo.description.moreJobsView": "Discover medical jobs near you with MedJobFinder. Customize searches to fit your career goals and preferences in healthcare.",
  "seo.description.policy": "Read our Privacy Policy to learn how MedJobFinder protects your personal information and respects your privacy during your job search.",
  "seo.description.terms": "Review the Terms & Conditions of MedJobFinder to understand your rights and responsibilities when using our healthcare job search platform.",
  "seo.title.about": "About Our Healthcare Job Connection Service",
  "seo.title.home": "Job Search for Medical & Healthcare Jobs Hiring Now",
  "seo.title.jobOffer": "%{cityName} Healthcare Jobs with %{partnerName} Near Me",
  "seo.title.moreJobsView": "Find Medical & Healthcare Jobs Near You",
  "service.AllServices": "All services",
  "service.categories": "Categories",
  "service.categories.header": "Services you may need",
  "service.categories.pageHeader": "Services selected for you",
  "service.filters.label": "Pick your %{typeName} service categories",
  "service.goToOffers": "Go to Jobs &gt;",
  "service.goToServices": "&lt; Services",
  "service.inCountry": "In %{country}",
  "service.offers.noData": "Sorry, there aren\u2019t any services available in your country.",
  "service.types.header": "Select your category to see more services",
  "setWorktown.error.label": "Error",
  "setWorktown.error.text": "Couldn\u2019t save your worktown",
  "share.email": "Share via email",
  "share.facebook": "Share on Facebook",
  "signup.confirmationEmail": "The confirmation link has been sent to your email. Please check your inbox.",
  "signup.resendEmail": "Resend confirmation link",
  "signup.time.dict.between_1_hour_and_24_hours": "between 1 and 24 hours",
  "signup.time.dict.didnt_finish_signup_process": "I didn\u2019t finish the signup process",
  "signup.time.dict.less_than_1_hour": "less than 1 hour",
  "signup.time.dict.more_than_24_hours": "more than 24 hours",
  "signupSuccess": "Thank you. We have sent you an email with a verification link. Please follow that link to make your account accessible.",
  "similarBlogPosts.header": "You might also like\u2026",
  "similarBlogPosts.loading": "Loading",
  "sitemap.cities": "Cities",
  "sitemap.countries": "Countries",
  "sort.header": "Sort by",
  "stat.rating": "Rating",
  "ui.component.divider.or": "OR",
  "ui.component.form.field.optional": "(optional)",
  "ukrainianArticles.otherCountries.title": "How You Can Help Ukraine Today",
  "unverifiedApply.applyAnyway": "Apply anyway",
  "unverifiedApply.header": "We cannot guarantee that %{partnerName} is hiring",
  "unverifiedApply.promoCarouselHeader": "Verified jobs that are hiring",
  "validation.max.string": ":attribute is too long (maximum is :max characters)",
  "validation.min.string": ":attribute is too short (minimum is :min characters)",
  "validation.password_requirement": "You need to use at least 8 characters",
  "verify.email": "Verify email",
  "verify.message": "Click the button below in order to receive a verification link.",
  "video.watchLive": "Watch live &gt;",
  "welcome.postpone": "I\u2019ll do it later",
  "welcome.previous": "Previous",
  "workingHours.fullTime": "Needs to be verified and confirmed with %{partnerName}",
  "workingHoursLabel": "Working hours",
};

module.exports = {
  phrases,
};
