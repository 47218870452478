<template>
  <div class="headers">
    <header
      v-if="!hideElements && !isRedirect"
      class="ui spaceless bottom top fixed inverted menu"
      :class="{
        '-scrolled': scrolledAway,
      }"
      :style="`top: ${offsetTop}px`"
    >
      <container-wrapper>
        <div class="logo-wrapper">
          <router-link
            :to="{ path: routeMixinHomePageRoutePath }"
            class="header item"
            :title="translate('seo.title.home')"
          >
            <aj-icon
              shape="logo"
              class="ui logo"
            />
          </router-link>
        </div>
        <div class="item right">
          <auth-button
            class="top-nav"
          />
        </div>
      </container-wrapper>
    </header>
    <div
      class="dim"
      :class="{ darkbgr: openedMenu }"
    />
  </div>
</template>

<style lang="scss" scoped>
  header.ui.inverted.menu {
    background: $cl-white;
    transition: box-shadow .2s ease;
    &.-scrolled {
      box-shadow: 0 -0 8px $cl-black-alpha-10;
    }

    .logo-wrapper {
      text-align: -webkit-right;
      margin-right: 117px;

      @media (max-width: $desktop-small) {
        margin-right: 70px;
      }

      @media (max-width: $tablet) {
        margin-right: 0;
      }

      .ui.logo {
        width: 125px;
        height: 40px;
        object-fit: contain;
      }
    }
  }

  .ui.container {
    padding-left: 15px;
    @media (min-width: $desktop-small) {
      width: 1280px;
      padding-left: 0;
    }
  }
</style>

<style lang="less" scoped>
  .ui.menu .item.right {
    flex: 1 1 auto;
    transition: font-size 500ms;
    justify-content: flex-end;
  }

  @media only screen and (max-width: @largestMobileScreen) {
    .ui.logo {
      width: 100px;
    }
  }

  .logo-wrapper {
    flex-direction: column;
    .header.item {
      padding: 0;
      height: 100%;
    }
  }

  .dim {
    display: none;
  }

  .dim.darkbgr {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
  }

  .item.right {
    justify-content: flex-end;
  }
  header.ui.menu {
    flex-wrap: wrap;

    .item {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: @largestMobileScreen) {
    .item.right.newVariant {
      .top-nav {
        padding-bottom: 10px;
      }
    }
  }
</style>

<script>
import { mapGetters, mapState } from 'vuex';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import { isMobile } from '@/js/helpers/breakpoints';
import { redirectRoutes } from '@/js/helpers/routes';
import { EventBus } from '@/js/helpers/utils';
import routesMixin from '@/js/mixins/routes';
import ContainerWrapper from '@/js/components/ui/container-wrapper';
import authButton from '@/js/components/auth-button';

export default {
  name: 'MenuHeader',

  components: {
    authButton,
    ContainerWrapper,
    AjIcon,
  },

  mixins: [routesMixin],

  props: {
    offsetTop: Number,
  },

  data () {
    return {
      scrolledAway: false,
      openedMenu: false,
    };
  },

  computed: {
    ...mapState({
      messages: state => state.messages,
    }),
    ...mapGetters('modal', ['hideElements']),

    isMobile () {
      return isMobile();
    },

    isRedirect () {
      return redirectRoutes.includes(this.$route.name);
    },
  },

  created () {
    EventBus.$on('body-scrolled', this.updateMenuDisplay);
    EventBus.$on('opened-new-menu', this.openMenu);
    EventBus.$on('close-new-menu', this.closeMenu);
  },

  destroyed () {
    EventBus.$off('body-scrolled', this.updateMenuDisplay);
    EventBus.$off('opened-new-menu', this.openMenu);
    EventBus.$off('close-new-menu', this.closeMenu);
  },

  methods: {
    updateMenuDisplay (position) {
      this.scrolledAway = position > 30;
    },

    openMenu () {
      if (this.isMobile && !this.openedMenu) {
        this.openedMenu = true;
      }
    },

    closeMenu () {
      if (this.isMobile) {
        this.openedMenu = false;
      }
    },
  },
};
</script>
