<template>
  <div class="auth-button">
    <user-menu />
  </div>
</template>

<style lang="scss" scoped>
  .auth-button {
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 15px;

    @media (min-width: $desktop-small) {
      margin-right: 0;
    }

    &.hidden {
      display: none;
    }
  }
</style>

<style lang="less" scoped>
  .auth-button {
    @media only screen and (min-width: @largestMobileScreen) {
      &.top-nav button {
        white-space: nowrap;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
      }

      .user-menu {
        transition: all 800ms ease;
      }
    }
  }
  .headers {
    .auth-button {
      @media only screen and (min-width: @largestMobileScreen) {
        margin-left: 30px;
      }
    }
  }
</style>

<script>
import userMenu from '@/js/components/user-menu';

export default {
  name: 'AuthButton',

  components: {
    userMenu,
  },
};
</script>
