export const redirectRoutes = [
  'redirect_to_partner',
];

export const routesWithRedesign = [
  'home',
  'policy',
  'jobs',
  'job_offer',
  'job_offer_canonical',
];
