<template>
  <div
    id="app"
    class="app"
    :class="{
      '-redesignedPage': isRedesignedPage,
      '-cookieOpened': !hidden,
    }"
  >
    <div
      id="vue-blind-stopper"
      class="blind-stopper"
    >
      <img
        title="MedJobFinder"
        alt="MedJobFinder"
        width="320"
        height="600"
        :src="blindStopperImageSrc"
      >
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  getCLS,
  getFID,
  getLCP,
  getFCP,
} from 'web-vitals';
import { sendGAEvent } from '@/js/helpers/analytics';
import { routesWithRedesign } from '@/js/helpers/routes';
import { Resources } from '@/js/helpers/resources';

export default {
  name: 'App',

  computed: {
    ...mapState('cookieNotice', ['hidden']),

    blindStopperImageSrc () {
      return new Resources('main-logos/logo_blindstopper_mjf.jpg')
        .getAssetUrl();
    },

    isRedesignedPage () {
      return routesWithRedesign.includes(this.$route.name);
    },
  },

  async mounted () {
    if (process.env.NODE_ENV === 'production') {
      this.logWebVitals();
    }
  },

  created () {
    this.hideBlindStopper();
  },

  methods: {
    hideBlindStopper () {
      const blindStopper = document.getElementById('original-blind-stopper');
      blindStopper.classList.add('-hidden');
    },

    logWebVitals () {
      getCLS(this.sendMetricToGoogleAnalytics);
      getFID(this.sendMetricToGoogleAnalytics);
      getLCP(this.sendMetricToGoogleAnalytics);
      getFCP(this.sendMetricToGoogleAnalytics);
    },

    sendMetricToGoogleAnalytics ({ name, delta, id }) {
      sendGAEvent({
        category: 'Web Vitals',
        action: name,
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        // (note: increase the multiplier for greater precision if needed).
        value: Math.round(name === 'CLS' ? delta * 1000 : delta),
        // The `id` value will be unique to the current page load. When sending
        // multiple values from the same page (e.g. for CLS), Google Analytics can
        // compute a total by grouping on this ID (note: requires `eventLabel` to
        // be a dimension in your report).
        label: `${this.$route.name} - ${id}`,
      });
    },
  },
};
</script>
