<template>
  <div
    id="cookieNag"
    class="cookieNag"
    :class="{ '-visible': !hidden }"
  >
    <aj-text
      class="cookieNag__text"
      body-low
      v-html="cookieNotice"
    />
    <aj-button
      subtle-dark
      small
      @click.prevent="hideCookieNotice"
    >
      OK
    </aj-button>
  </div>
</template>

<style lang="scss" scoped>
  .cookieNag {
    position: fixed;
    bottom: 0;
    z-index: 5005;
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 11px 16px;
    background-color: lighten($cl-black, 20%);
    opacity: .95;
    @media (min-width: $mobile-big) {
      justify-content: center;
    }

    &.-visible {
      display: flex;
    }

    &__text {
      margin-right: 20px;
      color: $cl-white
    }
  }
</style>

<script>
import { mapMutations, mapState } from 'vuex';
import AjButton from '@appjobs/ui/src/components/AjButton/AjButton';
import AjText from '@appjobs/ui/src/components/AjText/AjText';

export default {
  name: 'CookieNag',

  components: {
    AjButton,
    AjText,
  },

  computed: {
    ...mapState('cookieNotice', ['hidden']),
    ...mapState('i18n', ['language']),

    cookieNotice () {
      return this.translate('info.cookieNotice', {
        termsLink: this.$router.resolve({ name: 'terms' }).href,
      });
    },
  },

  mounted () {
    const cookieNoticeHeight = document.getElementById('cookieNag').clientHeight;
    this.setHeight(cookieNoticeHeight);
  },

  methods: {
    ...mapMutations('cookieNotice', ['hideCookieNotice', 'setHeight']),
  },
};
</script>
