import jobOffersService from '@/js/services/job-offers';

const mutations = {
  setFilteredCity (stateObj, city) {
    stateObj.filteredCity = city;
  },

  clearFilteredCity (stateObj) {
    stateObj.filteredCity = null;
  },

  setFilteredState (stateObj, state) {
    stateObj.filteredState = state;
  },

  clearFilteredState (stateObj) {
    stateObj.filteredState = null;
  },

  setFilteredCountryCode (stateObj, countryCode) {
    stateObj.filteredCountryCode = countryCode;
  },

  clearFilteredCountryCode (stateObj) {
    stateObj.filteredCountryCode = null;
  },

  setFilteredKeyword (stateObj, keyword) {
    stateObj.filteredKeyword = keyword;
  },

  clearFilteredKeyword (stateObj) {
    stateObj.filteredKeyword = null;
  },

  setMoreJobsOffer (stateObj, offer) {
    stateObj.moreJobsOffer = offer;
  },

  setMoreJobsOffers (stateObj, moreJobsOffers) {
    if (moreJobsOffers.length) {
      stateObj.moreJobsOffers = moreJobsOffers;
    } else {
      stateObj.moreJobsOffers = [];
    }
  },

  setIsLoadingMoreJobsOffer (stateObj, isLoading) {
    stateObj.isLoadingMoreJobsOffer = isLoading;
  },

  setIsLoadingMoreJobsOffers (stateObj, isLoading) {
    stateObj.isLoadingMoreJobsOffers = isLoading;
  },
};

const actions = {
  setFilteredCity ({ commit }, city) {
    commit('setFilteredCity', city);
  },

  clearFilteredCity ({ commit }) {
    commit('clearFilteredCity');
  },

  setFilteredState ({ commit }, state) {
    commit('setFilteredState', state);
  },

  clearFilteredState ({ commit }) {
    commit('clearFilteredState');
  },

  setFilteredCountryCode ({ commit }, countryCode) {
    commit('setFilteredCountryCode', countryCode);
  },

  clearFilteredCountryCode ({ commit }) {
    commit('clearFilteredCountryCode');
  },

  setFilteredKeyword ({ commit }, keyword) {
    commit('setFilteredKeyword', keyword);
  },

  clearFilteredKeyword ({ commit }) {
    commit('clearFilteredKeyword');
  },

  async getMoreJobsOffer ({ commit }, params) {
    if (!params) {
      return;
    }
    const requestParams = {
      city: params.citySlug,
      partner: params.partnerSlug,
      feed: params.feed,
    };

    if (params.id) {
      requestParams.id = params.id;
    }
    if (params.title) {
      requestParams.title_slug = params.title;
    }

    commit('setIsLoadingMoreJobsOffer', true);
    commit('setMoreJobsOffer', await jobOffersService.getMoreJobsOffer(requestParams));
    commit('setIsLoadingMoreJobsOffer', false);
  },

  async getMoreJobsOffers ({ commit }, params) {
    if (!params) {
      return;
    }

    commit('setIsLoadingMoreJobsOffers', true);
    commit('setMoreJobsOffers', await jobOffersService.getMoreJobsOffers(params));
    commit('setIsLoadingMoreJobsOffers', false);
  },
};

const state = {
  filteredCity: null,
  filteredState: null,
  filteredCountryCode: null,
  filteredKeyword: null,
  moreJobsOffer: null,
  moreJobsOffers: [],
  isLoadingMoreJobsOffer: false,
  isLoadingMoreJobsOffers: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
