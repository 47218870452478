const mutations = {
  hideCookieNotice (stateObj) {
    stateObj.hidden = true;
  },

  setHeight (stateObj, value) {
    stateObj.cookieNoticeHeight = value;
  },
};

const state = {
  hidden: false,
  cookieNoticeHeight: null,
};

export default {
  namespaced: true,
  state,
  mutations,
};
