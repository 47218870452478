<template>
  <footer class="footer">
    <breadcrumb-trail
      v-if="isShowBreadcrumbs"
      class="footer__breadcrumbs"
      show-on-mobile
    />
    <div class="footer-menu">
      <div class="item">
        <div class="icon-wrap">
          <router-link :to="{ path: routeMixinHomePageRoutePath }">
            <aj-icon
              shape="logo-dynamic"
              width="114"
              height="26"
              class="footer__logoIcon"
            />
          </router-link>
        </div>

        <div>
          <router-link
            class="link"
            :to="{ name: 'jobs' }"
            :title="translate('footer.title.exploreJobs')"
            @click.native="sendEvent('FooterMoreJobs')"
          >
            Explore jobs
          </router-link>
        </div>
        <div>
          <router-link
            class="link"
            :to="{ name: 'about' }"
            :title="translate('seo.title.about')"
          >
            {{ translate('footer.about') }}
          </router-link>
        </div>
        <div>
          <router-link
            class="link"
            :to="{ name: 'contact' }"
            :title="translate('footer.contact')"
          >
            {{ translate('footer.contact') }}
          </router-link>
        </div>
        <div>
          <router-link
            class="link"
            :to="{ name: 'terms' }"
            :title="translate('footer.terms')"
          >
            {{ translate('footer.terms') }}
          </router-link>
        </div>
        <div>
          <router-link
            class="link"
            :to="{ name: 'policy' }"
            :title="translate('footer.policy')"
          >
            {{ translate('footer.policy') }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="copyright">
      <aj-text>
        {{ copyrightText }}
      </aj-text>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  .link {
    color: $cl-white;
  }
  .copyright {
    padding: 25px 0 10px;
    border-top: 1px solid $cl-mako-lighten-64;
    color: $cl-white;
    text-align: center;
  }

  .footer__logoIcon {
    color: $cl-white;
  }

  .footer-menu {
    @media screen and (min-width: $desktop-big) {
      width: $desktop-big-layout-width;
    }
  }
</style>

<style lang="scss">
  .footer {
    background-color: $cl-secondary;
    padding: 15px;
    @media (min-width: $desktop-big) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .footer__breadcrumbs {
    padding: 12px 0;
    border-bottom: 1px solid $cl-mako-lighten-64;

    .breadcrumbs__link {
      min-height: 30px;
      font-size: 16px;
      line-height: 30px;
      color: $cl-white;

      &:hover {
        color: $cl-secondary;
      }

      &.-current {
        color: $cl-primary;

        &:hover {
          color: $cl-primary-darken-12;
        }
      }
    }

    .breadcrumbs__divider {
      color: $cl-mako-lighten-16;
    }
  }
</style>

<style lang="less" scoped>
  footer.ui.segment {
    padding-bottom: 0;
    padding-top: 0;
  }

  .footerLP.ten.wide.stackable {
    padding-bottom: 30px;
    padding-top: 30px;
    margin-bottom: 0;
  }

  .social {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 1.4em;

    .column {
      &.divider {
        flex: 1 0 100%;
        @media all and (min-width: @computerBreakpoint) {
          flex: 0 1 0px;
        }
      }
      .icon {
        margin: 0 0 10px 10px;
      }
    }
  }
</style>

<style lang="less">
  .footer-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 70px;
    margin: 0 auto;

    @media only screen and (max-width: @largestMobileScreen) {
      flex-wrap: wrap;
    }

    > .item {
      margin: 2rem;
      width: 50%;
      margin-left: 0;
      margin-right: 0;

      .icon-wrap {
        height: 50px;
      }

      .dropdown .text {
        font-size: 16px;
      }

      @media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
        flex: 0 1 auto;
      }
      @media only screen and (min-width: @computerBreakpoint) {
        flex: 0 1 180px;
      }

      &.menu {
        flex: 0.5 0 auto;
        font-size: 16px;

        @media only screen and (max-width: @largestMobileScreen) {
          flex: 1 0 100%;
          margin: 0 auto 2rem auto;
          order: 2;
        }
      }

      &.right {
        text-align: right;
        @media only screen and (min-width: @computerBreakpoint) {
          flex: 1 1 auto;
        }
      }

      .header {
        font-size: 18px;
        height: 50px;
        margin-bottom: auto;
        font-weight: bold;
      }

      .link {
        display: inline-block;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.5;
        margin-bottom: .8em;
      }

      .ui.stackable.grid {
        margin: auto;

        >.custom {
          padding: 0 !important;
          margin-bottom: 2em !important;

          @media only screen and (min-width: @tabletBreakpoint) {
            &:first-child {
              padding-right: 1rem !important;
            }

            &:last-child {
              padding-left: 1rem !important;
            }
          }
        }
      }
    }
  }
</style>

<script>
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import AjText from '@appjobs/ui/src/components/AjText/AjText';
import { isMobile } from '@/js/helpers/breakpoints';
import BreadcrumbTrail from '@/js/components/breadcrumb-trail';
import routesMixin from '@/js/mixins/routes';
import { sendGAEvent } from '../helpers/analytics';

export default {
  name: 'AppFooter',

  components: {
    BreadcrumbTrail,
    AjIcon,
    AjText,
  },

  mixins: [routesMixin],

  computed: {
    isMobile () {
      return isMobile();
    },
    isShowBreadcrumbs () {
      return this.isMobile && this.$route.name !== 'home';
    },
    copyrightText () {
      return this.translate('footer.copyrights.text', {
        currentYear: new Date().getFullYear(),
      });
    },
  },

  methods: {
    sendEvent (eventName) {
      sendGAEvent({
        category: eventName,
        action: 'click',
        label: this.$route.name,
      });
    },
  },
};
</script>
