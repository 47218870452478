import Vue from 'vue';
import { translate, interpolate } from '../helpers/i18n';

const Translate = {
  install (vueInstance) {
    vueInstance.mixin({
      methods: { translate, interpolate },
    });
  },
};

Vue.use(Translate);
