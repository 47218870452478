/**
 * Build a query string for a URL from an object containing key-value pairs.
 *
 * @param {Object} paramsObject - The object containing key-value pairs.
 * @returns {string} The formatted query string starting with "?".
 *
 * @example
 * const params = { city: "Denver", state: "CO" };
 * const query = buildQueryParams(params);  // Output: "?city=Denver&state=CO"
 */
function buildQueryParams (paramsObject) {
  const urlParamsArray = Object.entries(paramsObject).map(([key, value]) => {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value);
    return `${encodedKey}=${encodedValue}`;
  });

  return `?${urlParamsArray.join('&')}`;
}

/**
 * Removes query parameters from a given URL.
 *
 * @param {string} url - The URL from which to remove query parameters.
 * @returns {string} - The URL without query parameters.
 */

const removeQueryParamsFromUrl = url => {
  const parsedUrl = new URL(url);
  return parsedUrl.origin + parsedUrl.pathname;
};

/**
 * Replaces the last segment in the pathname of a given URL with the provided segment.
 *
 * @param {string} url - The URL whose last segment in the pathname needs to be replaced.
 * @param {string} segment - The segment to replace the last segment in the pathname with.
 * @returns {string} - The modified URL with the replaced segment.
 */

const replaceLastSegmentInPathname = (url, segment) => {
  const parsedUrl = new URL(url);
  const pathSegments = parsedUrl.pathname.split('/');
  pathSegments[pathSegments.length - 1] = segment;
  parsedUrl.pathname = pathSegments.join('/');
  return parsedUrl.toString();
};

module.exports = {
  buildQueryParams,
  removeQueryParamsFromUrl,
  replaceLastSegmentInPathname,
};
