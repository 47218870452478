<template>
  <div>
    <aj-loader
      :show="isPageLoading"
      class="componentLoader"
    />
    <more-jobs-view />
  </div>
</template>

<script>
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import { EventBus } from '@/js/helpers/utils';
import { seoTitle } from '@/js/helpers/seo';
import { translate } from '@/js/helpers/i18n';
import store from '@/js/store';

async function processFilters (route) {
  await processFilteredCity(route);
  await processFilteredState(route);
  await processFilteredCountryCode(route);
  await processfilteredKeyword(route);
}

async function processFilteredCity (route) {
  const filteredCity = route.query.city;
  if (filteredCity) {
    await store.dispatch('moreJobs/setFilteredCity', filteredCity);
  } else {
    await store.dispatch('moreJobs/clearFilteredCity');
  }
}

async function processFilteredState (route) {
  const filteredState = route.query.state;
  if (filteredState) {
    await store.dispatch('moreJobs/setFilteredState', filteredState);
  } else {
    await store.dispatch('moreJobs/clearFilteredState');
  }
}

async function processFilteredCountryCode (route) {
  const filteredCountryCode = route.query.country_code;
  if (filteredCountryCode) {
    await store.dispatch('moreJobs/setFilteredCountryCode', filteredCountryCode);
  } else {
    await store.dispatch('moreJobs/clearFilteredCountryCode');
  }
}

async function processfilteredKeyword (route) {
  const filteredKeyword = route.query.keyword;
  if (filteredKeyword) {
    await store.dispatch('moreJobs/setFilteredKeyword', filteredKeyword);
  } else {
    await store.dispatch('moreJobs/clearFilteredKeyword');
  }
}

function setSeoTitle ({ page }) {
  let title = translate('seo.title.moreJobsView');
  page = page ? parseInt(page.split('page-')[1]) : 1;

  if (page > 1) {
    title += ` - ${translate('pagination.page', { number: page })}`;
  }

  seoTitle(title);
}

export default {
  name: 'MoreJobsRoute',

  data () {
    return {
      isPageLoading: true,
    };
  },

  created () {
    EventBus.$on('more-jobs-view-loaded', () => {
      this.isPageLoading = false;
    });
  },

  async beforeRouteEnter (to, from, next) {
    await processFilters(to);
    setSeoTitle(to.params);
    next();
  },

  async beforeRouteUpdate (to, from, next) {
    setSeoTitle(to.params);

    next();
  },

  components: {
    AjLoader,
    MoreJobsView: () => import('@/js/views/MoreJobs/more-jobs-view'),
  },
};
</script>

<style lang="scss" scoped>
  .componentLoader {
    min-height: 100vh;
    position: relative;
  }
</style>
