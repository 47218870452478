<template>
  <div class="redirectPage">
    <template>
      <aj-loader
        :show="isLoading"
        full-size
        :header="translate('general.loading')"
        big
      />
      <div v-if="isError && !isLoading">
        <buffer-error />
      </div>
      <div
        v-if="!isError"
        class="redirectPage__info"
      >
        <aj-text
          headline-medium
          class="redirectPage__headline"
        >
          {{ translate('redirect.info.headline', { partnerName: partnerName }) }}
        </aj-text>
        <aj-loader
          class="redirectPage__loader"
          show
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss">
  .redirectPage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: $cl-white;

    &__info {
      width: 400px;
      max-width: 90%;
      color: $cl-white;
      margin-top: 20px;
    }

    &__headline {
      margin-bottom: 15px;
      color: $cl-primary;
      &.aj-text {
        font-size: 20px;
        line-height: 30px;

        @media (min-width: $tablet) {
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }
</style>

<script>
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import AjText from '@appjobs/ui/src/components/AjText/AjText';
import BufferError from '@/js/components/buffer-error';
import { OFFER_STATUS_EXPIRED } from '@/js/enums/jobOffersStatuses';
import { sendGAEvent } from '@/js/helpers/analytics';
import { modifySubIdInUrl } from '@/js/helpers/job-offers';
import { sentryCaptureException } from '@/js/helpers/sentry';
import jobOffersService from '@/js/services/job-offers';
import { postJobOfferLead } from '@/js/services/leadService';

export default {
  name: 'MoreJobsRedirectView',

  components: {
    AjLoader,
    AjText,
    BufferError,
  },

  props: {
    citySlug: {
      type: String,
      required: true,
    },

    feedId: {
      type: String,
      required: true,
    },

    offerId: {
      type: String,
      required: true,
    },

    partnerSlug: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      isError: false,
      isLoading: true,
      offer: {},
      OFFER_STATUS_EXPIRED,
      redirectViewTimestamp: null,
    };
  },

  computed: {
    partnerName () {
      return this.offer.partner ? this.offer.partner.name : 'partner';
    },
  },

  async created () {
    this.redirectViewTimestamp = new Date();
    await this.fetchData();
    if (!this.isError && this.offer.status !== OFFER_STATUS_EXPIRED) {
      this.postLead();
    }
  },

  methods: {
    async fetchData () {
      try {
        this.offer = await jobOffersService.getMoreJobsOffer({
          city: this.citySlug,
          partner: this.partnerSlug,
          feed: this.feedId,
          id: this.offerId,
        });
        if (!this.offer.status) {
          throw new Error('No job offer found');
        }
      } catch (error) {
        this.isLoading = false;
        this.isError = true;
        sentryCaptureException(error);

        return;
      }

      this.isLoading = false;
    },

    async postLead () {
      postJobOfferLead({
        offer: this.offer,
      })
        .then(
          result => {
            this.calculateTimeOnPage();
            let redirectUrl = result ? result.data.affiliate_url : this.offer.url;
            redirectUrl = modifySubIdInUrl(redirectUrl, '99999');
            window.location.href = redirectUrl;
          },
          error => {
            sentryCaptureException(error);
            this.calculateTimeOnPage();
            const redirectUrl = modifySubIdInUrl(this.offer.url, '99999');
            window.location.href = redirectUrl;
          },
        );
    },

    calculateTimeOnPage () {
      const timeSpentOnPage = (new Date() - this.redirectViewTimestamp) / 1000;
      sendGAEvent({
        category: 'MoreJobsRedirectPage',
        action: 'time-spent-on-page',
        label: this.$route.name,
        value: timeSpentOnPage,
      });
    },
  },
};
</script>
