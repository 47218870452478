<template>
  <component
    :is="modalName"
    :show="show"
    @close="hideModal"
    @confirm="confirmModal"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ModalPlaceholder',

  components: {
  },

  computed: {
    ...mapState({
      show: state => state.modal.show,
      modalName: state => state.modal.name,
    }),
  },

  watch: {
    show (newValue) {
      if (newValue) {
        document.body.classList.add('-modalOpened');
      } else {
        document.body.classList.remove('-modalOpened');
      }
    },
  },

  methods: {
    ...mapActions('modal', ['hideModal', 'confirmModal']),
  },
};
</script>
