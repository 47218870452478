function testStorage (storageObject) {
  if (typeof window !== 'undefined' && storageObject) {
    // Safari - in Private Browsing Modelooks
    // like it supports localStorage but all calls to setItem
    // throw QuotaExceededError.
    try {
      storageObject.setItem('localStorage', 1);
      storageObject.removeItem('localStorage');

      return storageObject;
    } catch (e) {
      // Silencing error
    }
  }

  // taken from vuex-persistedstate plugin
  class MemoryStorage {
    setItem (key, item) {
      this[key] = item;

      return item;
    }

    getItem (key) {
      return this[key];
    }

    removeItem (key) {
      delete this[key];
    }

    clear () {
      Object.keys(this).forEach(key => delete this[key]);
    }
  }

  return new MemoryStorage();
}

export const storage = (() => testStorage(window.localStorage))();
export const sessionStorage = (() => testStorage(window.sessionStorage))();
export const localStorageSupported = (storage.constructor.name !== 'MemoryStorage');
