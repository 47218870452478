import Cookies from 'js-cookie';
import { storage, localStorageSupported } from './storage';
import { refreshSession } from './gap';

export function getUserId () {
  const cookieUserId = Cookies.get('userId');

  const userId = cookieUserId || null;

  return userId;
}

export function sendGAEvent (data) {
  refreshSession();
  data = data || {};
  const eventCustomParams = { ...data.customParams };

  // prevent sending empty event
  if (!data.event && (!data.category || !data.action)) {
    return undefined;
  }

  window.dataLayer = window.dataLayer || [];

  return new Promise(resolve => {
    if (process.env.NODE_ENV === 'development') {
      // Useful in .env.local
      if (process.env.VUE_APP_LOG_EVENT === 'true') {
        // eslint-disable-next-line no-console
        console.log('Google Analytics Event', { ...data, event: data.event || 'GAEvent' });
      }
      resolve();
    }

    const timeout = setTimeout(resolve, 3000);
    window.dataLayer.push({
      event: data.event || 'GAEvent',
      eventCategory: data.category,
      eventAction: data.action,
      eventLabel: data.label,
      eventValue: data.value,
      eventUserId: getUserId(),
      ...eventCustomParams,
      eventCallback: () => {
        clearTimeout(timeout);
        resolve();
      },
    });
  });
}

export function storeEvent (data) {
  if (localStorageSupported && data) {
    storage.setItem('ga_event', JSON.stringify(data));
  }
}

export function triggerStoredEvent () {
  if (localStorageSupported) {
    const data = storage.getItem('ga_event');
    if (data) {
      sendGAEvent(JSON.parse(data));
      storage.removeItem('ga_event');
    }
  }
}

export function clearContentGrouping () {
  if (window.dataLayer) {
    window.dataLayer.push({
      contentGroup1: undefined,
      contentGroup2: undefined,
    });
  }
}

export function setContentGrouping (data) {
  window.dataLayer = window.dataLayer || [];

  const key = `contentGroup${data.index}`;
  window.dataLayer.push({
    [key]: data.group,
  });
}
