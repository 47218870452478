import { translate } from '@/js/helpers/i18n';

export default {
  build ({
    moreJobsView,
    moreJobsCityView,
    moreJobsOfferView,
    moreJobsOfferExpired,
  }) {
    const breadcrumbsArr = [];

    if (moreJobsView) {
      breadcrumbsArr.push({
        title: 'Search jobs',
        to: moreJobsOfferView || moreJobsOfferExpired ? { name: 'jobs' } : null,
      });
    }

    if (moreJobsCityView) {
      const cityText = [moreJobsCityView.cityName, moreJobsCityView?.state].filter(Boolean).join(', ');
      const queryObject = {
        city: moreJobsCityView.cityName,
        country_code: moreJobsCityView.countryCode,
      };
      if (moreJobsCityView.state) {
        queryObject.state = moreJobsCityView.state;
      }

      breadcrumbsArr.push({
        // title: translate('jobOffer.more_jobs.city', { city: cityText }),
        title: `Jobs in ${cityText}`,
        to: moreJobsOfferView || moreJobsOfferExpired ? {
          name: 'jobs',
          query: queryObject,
        } : null,
      });
    }

    if (moreJobsOfferView) {
      breadcrumbsArr.push({
        title: translate('breadcrumbs.jobOffer', {
          partnerName: moreJobsOfferView.partnerName,
          cityName: moreJobsOfferView.cityName,
        }),
      });
    }

    if (moreJobsOfferExpired) {
      breadcrumbsArr.push({
        title: translate('breadcrumbs.jobOffer.expired'),
      });
    }

    return breadcrumbsArr;
  },
};
