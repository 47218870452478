import phrasesService from '../../services/phrases';

const mutations = {
  setLanguage (stateObj, language) {
    stateObj.language = language;
  },

  setPhrases (stateObj, phrases) {
    stateObj.phrases = phrases;
  },

  setIsLanguageSet (stateObj) {
    stateObj.isLanguageSet = true;
  },
};

const actions = {
  async setLanguage ({
    commit, dispatch, state,
  }, language) {
    if (state.phrases === null || state.language !== language) {
      commit('setLanguage', language);
      commit('setIsLanguageSet');
      await dispatch('getPhrases');
    }

    if (!state.isLanguageSet) {
      commit('setIsLanguageSet');
    }
  },

  async getPhrases ({ commit, dispatch }) {
    if (prePopulated && prePopulated.phrases) {
      commit('setPhrases', prePopulated.phrases);
      prePopulated.phrases = null;
    } else {
      await dispatch('getClientSidePhrases');
    }
  },

  async getClientSidePhrases ({ commit }) {
    commit('setPhrases', await phrasesService.getPhrases());
  },
};

const state = {
  language: 'en',
  list: [],
  phrases: null,
  isLanguageSet: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
