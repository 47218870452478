import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import { storage } from '../helpers/storage';
import cookieNotice from './modules/cookieNotice';
import breadcrumbs from './modules/breadcrumbs';
import i18n from './modules/i18n';
import modal from './modules/modal';
import moreJobs from './modules/moreJobs';

Vue.use(Vuex);

// EXPERIMENTAL: purge old values
if (storage.getItem('vuex')) {
  storage.clear();
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'vuex_medjobfinder', // clear old values above if/when changing this key
      storage,
      setState: (key, state) => storage.setItem(key, JSON.stringify(state)),

      paths: [
        'i18n.language',
        'cookieNotice.hidden',
      ],
    }),
  ],

  state: {
    jobRequirements: [],
    messages: [],
  },

  modules: {
    breadcrumbs,
    cookieNotice,
    i18n,
    modal,
    moreJobs,
  },

  getters: {
    referralUrl () {
      const cookieReferralData = Cookies.get('referralData');
      if (cookieReferralData) {
        return JSON.parse(cookieReferralData).url;
      }

      return undefined;
    },
    isPushableMessage: state => (!!state.messages.find(item => item.pushable === true)),
  },

  mutations: {
    setJobRequirements (state, jobRequirements) {
      state.jobRequirements = jobRequirements;
    },

    filterJobRequirements (state, jobRequirementsIds) {
      state.jobRequirements.forEach((item, index, arr) => {
        const select = jobRequirementsIds.indexOf(item.id) > -1;
        const newValue = { ...item, _select: select };

        // Make Vue aware of array change.
        // Details: https://vuejs.org/v2/guide/list.html#Caveats
        Vue.set(arr, index, newValue);
      });
    },

    setMessage (state, {
      message, title, variation, html, closable, action, source, pushable,
    }) {
      const flashMessage = {
        id: state.messages.length + 1,
        title,
        variation,
        message,
        html: html || false,
        closable: typeof closable === 'undefined' ? true : closable,
        action,
        source,
        pushable: typeof pushable === 'undefined' ? true : pushable,
      };

      state.messages.push(flashMessage);
    },

    deleteMessage (state, messageId) {
      state.messages.splice(state.messages.findIndex(message => message.id === messageId), 1);
    },

    clearMessages (state) {
      state.messages = [];
    },
  },

  actions: {
    setMessage ({ commit }, messageData) {
      commit('cities/stopLoadingCities');
      commit('moreJobs/setIsLoadingMoreJobsOffers', false);
      commit('setMessage', messageData);
    },

    deleteMessage ({ commit }, messageId) {
      commit('deleteMessage', messageId);
    },

    clearMessages ({ commit }) {
      commit('clearMessages');
    },
  },
});
