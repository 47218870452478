export class Resources {
  constructor (asset) {
    this.asset = asset;
    this.comaSeparated = [];
    this.transformations = [];
  }

  getAssetUrl (type = 'image') {
    const transformations = [];

    if (this.comaSeparated.length) {
      transformations.push(this.comaSeparated.join(','));
    }

    if (this.transformations.length) {
      transformations.push(this.transformations.join('/'));
    }

    const transformationsString = transformations.length ? `${transformations.join('/')}/` : '';

    return `//res.cloudinary.com/hhgz8qnrm/${type}/upload/f_auto/${transformationsString}${this.asset}`;
  }
}
