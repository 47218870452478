/**
 * Handles clicks within vue component
 * and fires `onOutsideElementClick` method
 * when click appears to be outside this.$el
 */
export default {
  methods: {
    mixinHandleClick (event) {
      if (!this.$el.contains(event.target) && this.$el !== event.target) {
        this.onOutsideElementClick(event);
      }
    },
  },
  mounted () {
    // Wait for event loop to empty before attaching the event
    // to not fire it on some pending clicks
    setTimeout(() => {
      document.body.addEventListener('click', this.mixinHandleClick);
    });
  },
  beforeDestroy () {
    document.body.removeEventListener('click', this.mixinHandleClick);
  },
};
